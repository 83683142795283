<!--
 * @Author: John
 * @Date: 2022-02-22 14:10:02
 * @LastEditTime: 2022-02-22 15:23:04
 * @LastEditors: John
 * @Description: 
 * @FilePath: \jjms_ui\src\components\my-breadcrumb.vue
 * @Copyright John
-->
<template>
    <el-breadcrumb :separator="separator" style="margin-bottom: 30px;">
        <el-breadcrumb-item :to=" menu.url ? { path: menu.url } : ''" v-for="menu in data" :key="menu.title">{{menu.title}}</el-breadcrumb-item>
    </el-breadcrumb>
</template>
<script>
export default {
    name: 'my-breadcrumb',
    props: {
        separator: {
            type: String,
            default: '/'
        },
        data: {
            type: Array,
            required: true
        }
    }
}
</script>