<!--
 * @Author: John
 * @Date: 2022-02-22 11:45:19
 * @LastEditTime: 2022-04-19 15:51:11
 * @LastEditors: John
 * @Description: 当前定投列表
 * @FilePath: \jjms_ui\src\views\dtgl\list.vue
 * @Copyright John
-->
<template>
    <div>
        <my-breadcrumb :data="breadcrumbData" />
        <div class="content-wrap">
            <div class="toolbar">
                <el-button @click="toAddPage" icon="el-icon-document-add">添加</el-button>
                <el-button @click="toEditDt" icon="el-icon-edit-outline">编辑</el-button>
                <el-button @click="pauseDt" icon="el-icon-circle-close">暂停定投</el-button>
                <el-button @click="startDt" icon="el-icon-circle-check">恢复定投</el-button>
            </div>
            <el-table
                ref="dtTable"
                :data="tableData"
                style="width: 100%"
                highlight-current-row
                @current-change="handleCurrentChange"
                >
                <el-table-column
                    width="100"
                    type="index"
                    label="序号"
                 />
                <el-table-column
                    prop="jjCode"
                    label="基金代码"
                    width="120"
                />
                <el-table-column
                    prop="jjName"
                    label="基金名称"
                />
                <el-table-column
                    prop="period"
                    label="定投周期"
                    :formatter="formatPeriodShow"
                    width="150" 
                />
                <el-table-column
                    prop="money"
                    label="定投金额"
                    width="120" 
                />
                <el-table-column
                    prop="buyRate"
                    label="申购费率"
                    width="120" 
                />
                <el-table-column
                    prop="sellRate"
                    label="赎回费率"
                    width="120" 
                />
                <el-table-column
                    prop="nextDay"
                    label="下次定投日期" 
                    width="200"
                />
                <el-table-column
                    prop="status"
                    label="状态"
                    width="120"
                    :formatter="formatStatusShow"
                />
                <el-table-column
                    prop="createtime"
                    label="添加时间" 
                    width="230"
                />
            </el-table>
        </div>

        <el-dialog :title="formTitle" :visible.sync="dialogFormVisible" width="40%">
            <add-dt-form ref="addDtForm" :data="editDtFormData" :isDialog="true" />
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="addDt">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import myBreadcrumb from '@/components/my-breadcrumb.vue'
import addDtForm from '@/components/add-dt.vue'

import api from '../../api/req_api'


export default {
    name: 'dqdt',
    components: {
        myBreadcrumb,
        addDtForm
    },
    data() {
        return {
            breadcrumbData: [
                {title: '定投管理'},
                {title: '当前定投', url: '/main/dtgl-list'}
            ],
            tableData: [],
            currentRow: null,
            dialogFormVisible: false,
            editDtFormData: null,
            formTitle: ''
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        // 格式化定投周期字段显示
        formatPeriodShow(row, column, cellValue) {
            let text = '';
            cellValue+=''
            switch(cellValue) {
                case '1':
                    text = '按周';
                    break;
                case '2':
                    text = '按月';
                    break;
                default:
                    break;
            }
            return text;
        },
        // 格式化定投状态字段显示
        formatStatusShow(row, column, cellValue) {
            let text = '';
            switch(cellValue) {
                case 1:
                    text = '正常';
                    break;
                case 2:
                    text = '暂停';
                    break;
                case 3:
                    text = '移除';
                    break;
                default:
                    break;
            }
            return text;
        },
        // 表格行选中事件
        handleCurrentChange(currentRow) {
            this.currentRow = currentRow;
        },
        // 暂停定投
        pauseDt() {
            this.updateDtStatus(2, "已暂停");
        },
        startDt() {
            this.updateDtStatus(1, "已恢复定投")
        },
        updateDtStatus(status, successMsg) {
            if(this.currentRow === null) {
                this.$message.warning('请选择一条数据！');
                return;
            }
            this.currentRow.status = status; // 将状态改为暂停
            let param = JSON.parse(JSON.stringify(this.currentRow))
            console.log(param)
            api.updateDt(param)
                .then(data => {
                    console.log(data)
                    if(data != null) {
                        this.$message.info(successMsg);
                    }
                })
        },
        // 打开新增定投窗口
        toAddPage() {
            this.editDtFormData = null;
            this.formTitle = '添加定投';
            this.dialogFormVisible = true;
        },
        // 打开编辑定投窗口
        toEditDt() {
            if(this.currentRow === null) {
                this.$message.warning('请选择一条数据！');
                return;
            }
            this.editDtFormData = this.currentRow;
            this.formTitle = '编辑定投';
            this.dialogFormVisible = true;
        },
        // 保存定投
        addDt() {
            this.$refs['addDtForm'].submit(() => {
                this.$message.info("保存成功！");
                this.dialogFormVisible = false;
                this.loadData();
            });
        },
        loadData() {
            // 请求数据
            api.queryDtList()
                .then(data => {
                    if(data != null) {
                        this.tableData = data;
                    }
                })
        }
    }
}
</script>
