<!--
 * @Author: John
 * @Date: 2022-03-02 14:56:30
 * @LastEditTime: 2023-09-01 16:16:21
 * @LastEditors: John Li john.li@xiazhi.co
 * @Description: 添加定投
 * @FilePath: \jjms_ui\src\components\add-dt.vue
 * @Copyright John
-->
<template>
    <div>
        <el-form :model="formData" :rules="formRule" ref="addDtForm" label-width="100px" :inline-message="true">
            <el-form-item label="基金名称" prop="jjName">
                <!-- <el-autocomplete
                    class="inline-input"
                    v-model="formData.jjName"
                    :fetch-suggestions="querySearch"
                    placeholder="请输入内容"
                    @select="handleSelect" :disabled="isEditMode" style="width: 100%" /> -->
                <el-input v-model="formData.jjName" :disabled="true" />
            </el-form-item>
            <el-form-item label="基金代码" prop="jjCode">
                <el-input v-model="formData.jjCode" :disabled="isEditMode" @blur="queryByCode"></el-input>
            </el-form-item>
            <el-form-item label="定投金额" prop="money">
                <el-input v-model="formData.money"></el-input>
            </el-form-item>
            <el-form-item label="申购费率" prop="buyRate">
                <el-input v-model="formData.buyRate"></el-input>
            </el-form-item>
            <el-form-item label="赎回费率" prop="sellRate">
                <el-input v-model="formData.sellRate"></el-input>
            </el-form-item>
            <el-form-item label="定投周期" prop="period">
                <el-select v-model="formData.period" style="width: 100%">
                    <el-option label="按周" value="1"></el-option>
                    <el-option label="按月" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="下次定投日期" prop="nexDay">
                <el-date-picker
                    v-model="formData.nextDay"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期" style="width: 100%">
                    </el-date-picker>
            </el-form-item>
            <el-form-item v-if="!isDialog">
                <el-button type="primary" @click="submit">保存</el-button>
            </el-form-item>
            <el-form-item>
                <div style="color: red;">支持输入基金名称模糊查询，也支持输入基金代码精确查询</div>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import api from '../api/req_api'

export default {
    name: 'addDtForm',  // 添加定投表单
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        isDialog: Boolean
    },
    data() {
        return {
            formData: {
                jjName: '',
                jjCode: '',
                money: 1000,
                period: '',
                nextDay: '',
                buyRate:0,
                sellRate: 0
            },
            formRule: {
                jjName: [
                    {required: true, message: '请输入基金名称', trigger: 'select'}
                ],
                jjCode: [
                    {required: true, message: '请输入基金代码', trigger: 'blur'}
                ],
                money: [
                    {required: true, message: '请输入定投金额', trigger: 'blur'}
                ],
                period: [
                    {required: true, message: '请选择定投周期', trigger: 'blur'}
                ],
                nextDay: [
                    {required: true, message: '请输入下次定投日期', trigger: 'blur'}
                ],
            },
            isEditMode: false
        }
    },
    methods: {
        submit(callback) {
            this.$refs['addDtForm'].validate((valid) => {
                if (valid) {
                    let submitFn = this.isEditMode ? api.updateDt : api.addDt;
                    if(submitFn) {
                        submitFn(this.formData)
                        .then(data => {
                            if(data != null) {
                                this.$message.info('保存成功!');
                                if(callback) callback();
                            }
                        })
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        querySearch(keywords, callback) {
            api.queryJJByName(keywords)
                .then(data => {
                    if(data != null) {
                        callback(data);
                    }
                })
        },
        handleSelect(obj) {
            // 填充选择的基金
            this.formData.jjCode = obj.jjCode;
        },
        queryByCode() {
            let jjCode = this.formData.jjCode;
            if(jjCode) {
                api.queryJJByCode(jjCode)
                    .then(data => {
                        if(data != null) {
                            this.formData.jjName = data.jjName;
                        }
                    });
            }
        },
        initData(data) {
            if(!data) {
                this.formData = {};
                this.isEditMode = false;  // 添加模式
            } else {
                data.period = data.period+'';  // 修复数值类型不会默认选中问题
                this.formData = data;
                this.isEditMode = true;  // 编辑模式
            }
        }
    },
    created(){
        console.log('this.data: ' + this.data)
        this.initData(this.data);
    },
    watch: {
        data: function(newVal) {
            this.initData(newVal);
        }
    }
}
</script>